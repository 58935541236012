.buttonContainer {
  display: flex;
}

@media screen and (max-width: 480px) {
  .buttonContainer {
    display: flex;
    flex-direction: column;
  }

  .registerButton {
    margin-bottom: 12px !important;
  }
}
