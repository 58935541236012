button.html5-qrcode-element,
select.html5-qrcode-element,
[id^='html5-qrcode-button-camera-'],
[id^='html5-qrcode-select-camera'] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: none;
  box-shadow: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 50px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #818182;
  margin-bottom: 0 !important;
}

div[id='html5qr-code-full-region__scan_region'] {
  height: 464px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;

  overflow: hidden;

  video {
    object-fit: cover !important;
    //height: 464px !important;

    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    overflow: auto;
    //transform: scaleX(-100%);
    margin-left: 0px !important;
  }
}

div[id='html5qr-code-full-region__header_message'] {
  display: none !important;
}

img[src*='data:image/svg+xml;base64'] {
  display: none;
}

span[id='html5-qrcode-anchor-scan-type-change'] {
  display: none !important;
}

div[id='html5qr-code-full-region'] {
  width: 100%;
  height: 464px !important;
  margin-top: 0px;
  margin-left: 0px !important;
}

div[id='html5qr-code-full-region__dashboard'] {
  margin-top: -70px !important;
}

div[id='html5qr-code-full-region__dashboard_section_csr'] {
  margin-top: -80px !important;

  div {
    span {
      display: none !important;
    }
  }

  span {
    select {
      margin-bottom: 10px !important;
    }
  }
}

.codeInput {
  margin-top: 8px;
  width: 500px;
}

@media screen and (max-width: 480px) {
  div[id='html5qr-code-full-region__scan_region'] {
    height: 500px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;

    video {
      object-fit: cover !important;
      height: 500px !important;
      position: absolute;
      margin-left: 0px !important;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      overflow: auto;
    }
  }

  div[id='html5qr-code-full-region__header_message'] {
    display: none !important;
  }

  img[src*='data:image/svg+xml;base64'] {
    display: none;
  }

  span[id='html5-qrcode-anchor-scan-type-change'] {
    display: none !important;
  }

  div[id='html5qr-code-full-region'] {
    width: 100%;
    height: 500px !important;
    margin-top: 0px;
  }

  div[id='html5qr-code-full-region__dashboard'] {
    margin-top: -70px !important;
  }

  div[id='html5qr-code-full-region'] {
    width: 100%;
    height: 500px !important;
    margin-top: -100px;
  }

  div[id='html5qr-code-full-region__scan_region'] {
    width: 100%;
    height: 500px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;


    video {
      object-fit: cover !important;
      position: relative;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      overflow: auto;
      margin-left: 0px !important;
      margin-right: 0px !important;
      border: 1px solid silver !important;
    }
  }

  div[id='html5qr-code-full-region__dashboard_section_csr'] {
    div {
      input[id='html5-qrcode-input-range-zoom'] {
        width: 90% !important;
      }
    }
  }
}